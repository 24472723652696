<template>
    <TheBasePageLayout
        pageHeading="Adoption Detail"
        canGoBack
        @goBack="$router.push( { path: '/adoptions' } )"
    >
        <!-- Main Slot -->
        <div
            id="adoption-detail-container"
            slot="main"
        >

            <!--<div class="box-shadow title-container">
                <h3>Todo...</h3>
                <p>-> Need title data from endpoint</p>
                <p>-> What title info should be displayed? ( ISBN, Author, Description, Edition, anything else? )</p>
                <p>-> Need to be able to re-grab the materialLink from an endpoint if the user hits this URL directly</p>
                <p>-> How do we want to display these Catalog Listings? What information is most important?</p>
            </div>-->

            <!-- Title Info -->
            <div class="box-shadow title-container">
                <h3 class="detail-field-heading">Title</h3>

                <LoaderIconSmall v-if="inFlightDetail"/>

                <div v-if="hasDetail">
                    <p>Title: {{ detail.description }}</p>
                    <p>EAN: {{ detail.productCode }}</p>
                </div>
            </div>

            <!-- Adoption Info -->
            <div class="box-shadow adoption-container">
                <h3 class="detail-field-heading">Adoption</h3>

                <!-- Material Link -->
                <div v-if="hasDetail">
                    <a
                        v-if="detail.materialLink"
                        :href="detail.materialLink"
                    >
                        View on Butler Central
                    </a>
                    <p v-else >
                        No link available
                    </p>
                </div>

            </div>

            <!-- Catalog Listings -->
            <div class="box-shadow listing-container">
                <h3 class="detail-field-heading">Catalog Listings - ( {{ catalogListingCount }} )</h3>

                <LoaderIconSmall v-if="inFlightListings"/>

                <div v-if="hasCatalogListings">
                    <CatalogListing
                        v-for="listing in catalogListings"
                        :key="listing.id"
                        :catalogListing="listing"
                    />
                </div>
            </div>

        </div>

    </TheBasePageLayout>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Api
import AdoptionApi from '@/api/AdoptionApi';

// Utils
import ObjectUtil from '@/utils/ObjectUtil';

// Components
import TheBasePageLayout from '@/components/base/TheBasePageLayout';
import LoaderIconSmall from '@/components/base/loaders/LoaderIconSmall';

import CatalogListing from './adoptionDetail/CatalogListing';

export default {
    name: 'AdoptionDetail',
    components: {
        TheBasePageLayout,
        LoaderIconSmall,
        CatalogListing,
    },
    data() {
        return {
            detail: {},
            inFlightDetail: false,
            catalogListings: {},
            inFlightListings: false,
        };
    },
    computed: {
        ...mapGetters( {
            authToken: 'User/authString',
        } ),

        /** @returns { Number || String } */
        adoptionId() {
            return this.$route.params.id;
        },

        /** @returns { Number } */
        catalogListingCount() {
            return Object.values( this.catalogListings ).length;
        },
        /** @returns { Boolean } */
        hasCatalogListings() {
            return this.catalogListingCount > 0;
        },

        /** @returns { Boolean } */
        hasDetail() {
            return ObjectUtil.isNotEmpty( this.detail );
        },
    },
    mounted() {
        if ( this.adoptionId ) {
            this.getAdoptionDetailInfo();
            this.getCatalogListingsByAdoptionId();
        } else {
            this.$router.push( { path: '/adoptions' } );
        }
    },
    methods: {
        async getAdoptionDetailInfo() {
            this.inFlightDetail = true;
            try {
                const { data } = await AdoptionApi.getAdoptionsByFilters( {
                    authToken: this.authToken,
                    payload: { adoptionMaterialId: this.adoptionId },
                } );
                if ( data.adoptionMaterialItems && data.adoptionMaterialItems.length && data.adoptionMaterialItems.length > 0 ) {
                    [ this.detail ] = data.adoptionMaterialItems;
                }
            } catch ( error ) {
                console.error( 'Error in getAdoptionDetailInfo: ', error );
            } finally {
                this.inFlightDetail = false;
            }
        },

        async getCatalogListingsByAdoptionId() {
            this.inFlightListings = true;
            try {
                const { data } = await AdoptionApi.getCatalogListingsByAdoption( {
                    authToken: this.authToken,
                    payload: { adoptionMaterialId: this.adoptionId },
                } );
                this.catalogListings = ObjectUtil.normalize( data.catalogListings, 'id' );
            } catch ( error ) {
                console.error( 'Error in getCatalogListingsByAdoptionId: ', error );
            } finally {
                this.inFlightListings = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
#adoption-detail-container {

    :not(:last-child) {
        margin-bottom: 1rem;
    }

    .box-shadow {
        padding: 1rem;
    }
}
</style>
