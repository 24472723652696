<template>
    <div class="box-shadow catalog-listing-container">

        <div class="data-point">
            <p class="label">Location</p>
            <p class="value">{{ catalogListing.locationName }}</p>
        </div>

        <div class="data-point">
            <p class="label">Division</p>
            <p class="value">{{ catalogListing.divisionName }}</p>
        </div>

        <div class="data-point">
            <p class="label">Department</p>
            <p class="value">{{ catalogListing.department.code }}</p>
        </div>

        <div class="data-point">
            <p class="label">Term</p>
            <p class="value">{{ catalogListing.termName }}</p>
        </div>

        <div class="data-point">
            <p class="label">Course</p>
            <p class="value">{{ catalogListing.course.code }}</p>
        </div>

        <div class="data-point">
            <p class="label">Section</p>
            <p class="value">{{ catalogListing.section.code }}</p>
        </div>

        <div class="data-point">
            <p class="label">Section Start Date</p>
            <p class="value">{{ catalogListing.section.startDate }}</p>
        </div>

        <div class="data-point">
            <p class="label">Required</p>
            <p class="value">{{ catalogListing.required }}</p>
        </div>

        <div class="data-point">
            <p class="label">QTP</p>
            <p class="value">{{ catalogListing.qtp }}</p>
        </div>

        <div class="data-point">
            <p class="label">Note</p>
            <p class="value">{{ catalogListing.note }}</p>
        </div>

        <div class="data-point">
            <p class="label">External Listing Note</p>
            <p class="value">{{ catalogListing.externalListingNote }}</p>
        </div>

        <div class="data-point">
            <p class="label">Status</p>
            <p class="value">{{ catalogListing.status }}</p>
        </div>

    </div>
</template>

<script>
export default {
    name: 'CatalogListing',
    props: {
        catalogListing: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.catalog-listing-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    padding: 1rem;
    margin: 1rem;

    .data-point {
        margin-bottom: 1rem;
        flex: 1 1 auto;

        .label {
            color: #808080;
        }

        .value {
            margin-left: 1rem;
        }
    }
}
</style>
